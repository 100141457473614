var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"pb-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('h3',{staticClass:"text-xl"},[_vm._v(" Identificação ")]),_c('div',{staticClass:"bg-ibm-gray-10 p-4 mb-8"},[_c('div',{staticClass:"grid grid-cols-2 gap-x-4 mb-4"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Status"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statuses),function(status){return _c('cv-select-option',{key:status.name,attrs:{"value":status.value,"invalid-message":_vm.form.errors.type || errors[0]}},[_vm._v(" "+_vm._s(status.name)+" ")])}),1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"eventSubscriptionId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Autor (obrigatório já estar inscrito no Evento)"},model:{value:(_vm.form.eventSubscriptionId),callback:function ($$v) {_vm.$set(_vm.form, "eventSubscriptionId", $$v)},expression:"form.eventSubscriptionId"}},_vm._l((_vm.subscriptions),function(subscription){return _c('cv-select-option',{key:subscription.id,attrs:{"value":subscription.id,"invalid-message":_vm.form.errors.eventSubscriptionId || errors[0]}},[_vm._v(" "+_vm._s(subscription.name_cred)+" ")])}),1)]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"tag":"div","rules":"required","name":"eventScheduleId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Programação Científica"},model:{value:(_vm.form.eventScheduleId),callback:function ($$v) {_vm.$set(_vm.form, "eventScheduleId", $$v)},expression:"form.eventScheduleId"}},_vm._l((_vm.schedules),function(schedule){return _c('cv-select-option',{key:schedule.id,attrs:{"value":schedule.id,"invalid-message":_vm.form.errors.eventScheduleId || errors[0]}},[_vm._v(" "+_vm._s(schedule.title)+" ")])}),1)]}}],null,true)})],1),_c('h3',{staticClass:"text-xl"},[_vm._v(" Pergunta ")]),_c('div',{staticClass:"lg:col-span-12 bg-ibm-gray-10 p-4 mb-8"},[_c('ValidationProvider',{staticClass:"mb-4",attrs:{"tag":"div","name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.title || errors[0],"label":"Pergunta"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('cv-text-input',{staticClass:"lg:col-span-12",attrs:{"label":"Texto adicional"},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1),_c('h3',{staticClass:"text-xl"},[_vm._v(" Resposta ")]),_c('cv-text-input',{staticClass:"lg:col-span-12 bg-ibm-gray-10 p-4 mb-8",attrs:{"label":"Resposta"},model:{value:(_vm.form.answer),callback:function ($$v) {_vm.$set(_vm.form, "answer", $$v)},expression:"form.answer"}}),_c('div',[_c('div',{staticClass:"w-full lg:w-1/2"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),_c('div',[(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)])],1)]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }